import React from "react"
import Form from '../components/Form'
import Footer from '../components/Footer'

export default function Home() {
  return (
    <>
      <Form />
      <Footer />
    </>
  )
}
