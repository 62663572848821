import React from "react"
import Input from "./Input"

const VALUE_MAX = 10000;
const VALUE_MIN = 0;

function Counter(props) {
    const total = Object.entries(props.uniqueTests).map(e => e[1]).reduce((acc, v) => acc + v, 0) * 1;
    return (
        <div className="py-4">
            Unique test-cases found so far:  {total} / 11
        </div>
    )
}

function Message(props) {
    const message = props.message;
    return (
        <div className="text-green-500">{message ? "This triangle is  " + message : ""}</div>
    )
}

function Warning(props) {
    const warning = props.warning;
    return (
        <div className="text-orange-500">{warning ? warning : ""}</div>
    )
}

class Form extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            sides: {
                sideA: undefined,
                sideB: undefined,
                sideC: undefined,
            },
            errors: {
                uniqueTests: {},
            },
            message: undefined,
            warning: undefined,
        }

        this.handleEvaluate = this.handleEvaluate.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        const sides = this.state.sides;
        sides[e.target.name] = e.target.value;
        this.setState({ sides });
    }

    assignError(errors, type, entries, message) {
        for (const entry of entries) {
            if (!errors[entry[0]]) {
                errors[entry[0]] = message;
            }
        }
        this.countTestCase(errors, entries, type);
    }

    countTestCase(errors, entries, type) {
        if (entries.length > 0) {
            errors.uniqueTests[type] = 1;
        }
    }

    chkNullEntries(sides, errors) {
        const nullEntries = Object.entries(sides).filter(entry => !entry[1]);
        this.assignError(errors, "null", nullEntries, "Value must not be null");
        return nullEntries.length === 0;
    }

    chkEmptyEntries(sides, errors) {
        const emptyEntries = Object.entries(sides).filter(entry => entry[1] && entry[1].trim().length < 1);
        this.assignError(errors, "empty", emptyEntries, "Value must not be empty");
        return emptyEntries.length === 0;
    }

    chkNonNumeric(sides, errors) {
        const regex = new RegExp('^\\-?([1-9]+|0)(\\.?[0-9]+)?$')  // 0.123 or 1.123, or -1.23, but not .123 or -0.123
        const nonNumeric = Object.entries(sides).filter(entry => !regex.test(entry[1]));
        this.assignError(errors, "numberic", nonNumeric, "Value must be numeric");
        return nonNumeric.length === 0;
    }

    chkSpecial(sides, errors) {
        const regex = new RegExp('^[0-9a-zA-Z\\.\\-\\s]+$')
        const special = Object.entries(sides).filter(entry => !regex.test(entry[1]));
        this.assignError(errors, "special", special, "Value must not contain special characters");
        console.log(`special ${JSON.stringify(errors)}`)
        return special.length === 0;
    }

    chkNegative(sides, errors) {
        const negative = Object.entries(sides).filter(entry => entry[1] * 1 < 0);
        this.assignError(errors, "negative", negative, "Value must be positive");
        return negative.length === 0;
    }

    chkBigInput(sides, errors) {
        const overflow = Object.entries(sides).filter(entry => entry[1] * 1 > VALUE_MAX);
        this.assignError(errors, "overflow", overflow, "Value must be less than or equal to 10000");
        return overflow.length === 0;
    }

    chkMinBound(sides, errors) {
        const minBound = Object.entries(sides).filter(entry => entry[1] * 1 === VALUE_MIN);
        this.assignError(errors, "minBound", minBound, "Value must be positive");
        this.countTestCase(errors, minBound, "minBound");
        return minBound.length === 0;
    }

    chkMaxBound(sides, errors) {
        const maxBound = Object.entries(sides).filter(entry => entry[1] * 1 === VALUE_MAX);
        this.countTestCase(errors, maxBound, "maxBound");
        return true;
    }

    // This function will check if Triangle can be drawn or not based on the theoram a + b > c.
    chkTrianglePossible(sides) {
        const { sideA, sideB, sideC } = sides;
        const sideAnum = sideA * 1;
        const sideBnum = sideB * 1;
        const sideCnum = sideC * 1;
        if (sideAnum + sideBnum <= sideCnum || sideBnum + sideCnum <= sideAnum || sideAnum + sideCnum <= sideBnum) {
            throw new Error("Triangle with such sides is not possible");
        }
    }

    chkTriangleType(sides) {
        const { sideA, sideB, sideC } = sides;
        const sideAnum = sideA * 1;
        const sideBnum = sideB * 1;
        const sideCnum = sideC * 1;

        if (sideAnum !== sideBnum && sideAnum !== sideCnum && sideBnum !== sideCnum) {
            return "scalene";
        } else if (sideAnum === sideBnum && sideBnum === sideCnum) {
            return "equilateral";
        } else if (sideAnum === sideBnum || sideAnum === sideCnum || sideBnum === sideCnum) {
            return "isosceles";
        } else {
            return '';
        }

    }

    handleEvaluate(event) {
        event.preventDefault();

        const localErrors = {
            uniqueTests: this.state.errors.uniqueTests || {}
        };
        try {
            const message = this.validate(localErrors);
            if (message) {
                localErrors.uniqueTests[message] = 1;
            }
            this.setState({
                errors: localErrors,
                message,
                warning: undefined,
            });
        } catch (error) {
            localErrors.uniqueTests["ex"] = 1;
            this.setState({
                warning: error.message,
                errors: {
                    uniqueTests: localErrors.uniqueTests
                },
                message: undefined
            });
        }
    }

    validate(errors) {
        const sides = this.state.sides;

        let noErrors = [
            () => this.chkNullEntries(sides, errors),
            () => this.chkEmptyEntries(sides, errors),
            () => this.chkSpecial(sides, errors),
            () => this.chkNonNumeric(sides, errors),
            () => this.chkNegative(sides, errors),
            () => this.chkMinBound(sides, errors),
            () => this.chkMaxBound(sides, errors),
            () => this.chkBigInput(sides, errors),
        ].reduce((acc, fun) => {
            if (acc) {
                acc = fun.call();
                return acc;
            }
            return false;
        }, true);

        if (noErrors) {
            this.chkTrianglePossible(sides);
            return this.chkTriangleType(sides);
        } else {
            return undefined;
        }
    }

    render() {
        return (
            <div className="container mx-auto px-4">
                <div>
                    <section className="py-8 px-4">
                        <div className="flex flex-wrap mx-2">
                            <div className="lg:w-2/5 px-2 lg:pr-16 mb-6 lg:mb-0">
                                <h2 className="text-3xl">Triangle Test</h2>
                            </div>
                            <div className="lg:w-3/5 px-2">
                                <p className="py-6">Suppose your program accepts input as three sides of a triangle and gives output on what type of triangle is this i.e. Scalene (no sides are same), Isosceles (any two sides are same) or Equilateral (All the three sides are same).</p>
                                <p className="py-1">Note that each side of a triangle should be less than or equal to {VALUE_MAX}</p>
                                <p className="py-1">You have to come up with different test cases to test this program.</p>
                            </div>
                        </div>
                    </section>
                    <Input title="Side A" name="sideA" value={this.state.sideA} handleChange={this.handleChange} errors={this.state.errors['sideA']} />
                    <Input title="Side B" name="sideB" value={this.state.sideB} handleChange={this.handleChange} errors={this.state.errors['sideB']} />
                    <Input title="Side C" name="sideC" value={this.state.sideC} handleChange={this.handleChange} errors={this.state.errors['sideC']} />
                    <section className="flex-center flex-grow-0 text-center">
                        <Warning warning={this.state.warning} />
                        <Message message={this.state.message} />
                    </section>
                    <section className="px-4 text-center">
                        <Counter uniqueTests={this.state.errors.uniqueTests} />
                        <div className="py-4">
                            <button className="inline-block py-4 px-8 leading-none text-white bg-indigo-500 hover:bg-indigo-600 rounded shadow" onClick={this.handleEvaluate}>Evaluate</button>
                        </div>
                    </section>
                </div>
            </div>
        )
    }

}

export default Form