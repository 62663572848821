import React from "react"

const Input = (props) => {

    return (
        <div className="mb-6 flex flex-auto">
            <div className="px-6">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sideA">{props.title}</label>
            </div>
            <div className="px-6 flex-auto">
                <input className={(props.errors ? "border-red-500" : "") + " appearance-none block w-full py-3 px-4 leading-tight text-gray-700 bg-gray-200 focus:bg-white border border-gray-200 focus:border-gray-500 rounded focus:outline-none"}
                    type="text"
                    name={props.name}
                    defaultValue={props.value}
                    onChange={props.handleChange}></input>
                <span className="text-red-500">{props.errors}</span>
            </div>
        </div>
    )

}

export default Input